import { WebCartItem } from '@/models/Cart';

export default class TrackingService {

    public trackAddToCart(item: WebCartItem) {

        if (!item)
            return;
            
        // facebook pixel tracking
        if (typeof (window as any).fbq === 'function' && item.quantity > 0)
        {
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));

            (window as any).fbq('track', 'AddToCart', {
                'content_name': item.name,
                'content_category': '',
                'content_ids': [item.productid],
                'content_type': 'product',
                'value': unitpriceincvat.toFixed(2),
                'currency': item.unitprice.currency
            });
        }

        // ga4 tracking
        if (typeof (window as any).gtag === 'function' && item.quantity > 0)
        {
            const linepriceincvat = item.unitprice.price * item.quantity * (1 + (item.unitprice.vatrate / 100));
            let unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            let unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            if (item.quantity < 0)
            {
                unitpriceincvat = unitpriceincvat * -1;
                unitpricediscountamountincvat = unitpricediscountamountincvat * -1;
            }

            (window as any).gtag('event', 'add_to_cart', {
                currency: item.unitprice.currency,
                items: [{
                  'item_id': item.productid,
                  'item_name': item.name,
                  coupon: '',
                  discount: unitpricediscountamountincvat.toFixed(2),
                  affiliation: '',
                  'item_brand': '',
                  'item_category': '',
                  'item_variant': '',
                  price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                  currency: item.unitprice.currency,
                  quantity: item.quantity
                }],
                value: linepriceincvat.toFixed(2)
            });
        }

        // gtm tracking
        if (typeof (window as any).dataLayer === 'object' && (window as any).gtm_addtocard)
        {
            const event = item.quantity > 0 ? "add_to_cart" : "remove_from_cart";
            const quantity = item.quantity > 0 ? item.quantity : (-1 * item.quantity);
            const linepriceincvat = item.unitprice.price * quantity * (1 + (item.unitprice.vatrate / 100));
            const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
            const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

            (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            (window as any).dataLayer.push({
                event: event,
                ecommerce: {
                    currency: item.unitprice.currency,
                    value: linepriceincvat,
                    items: [{
                        'item_id': item.productid,
                        'item_name': item.name,
                        discount: unitpricediscountamountincvat,
                        price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                        currency: item.unitprice.currency,
                        quantity: quantity
                    }],
                }
            });
        }
    }

    public trackEmptyCart(items: WebCartItem[]) {

        if (!items?.length)
        return;

        // gtm tracking
        if (typeof (window as any).dataLayer === 'object' && (window as any).gtm_addtocard)
        {
            const linepriceincvat = items.reduce((output, item) => output + (item.lineprice.price * (1 + (item.lineprice.vatrate / 100))), 0);

            (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            (window as any).dataLayer.push({
                event:  'remove_from_cart',
                ecommerce: {
                    currency: items[0].unitprice.currency,
                    value: linepriceincvat.toFixed(2),
                    items: items.map(item => {
                        const unitpriceincvat = item.unitprice.price * (1 + (item.unitprice.vatrate / 100));
                        const unitpricediscountamountincvat = item.unitprice.discountamount * (1 + (item.unitprice.vatrate / 100));

                        return {
                            'item_id': item.productid,
                            'item_name': item.name,
                            discount: item.unitprice.discountamountincvat,
                            price: (unitpriceincvat + unitpricediscountamountincvat).toFixed(2),
                            currency: item.unitprice.currency,
                            quantity: item.quantity
                        }
                    }),
                }
            });
        }
    }
}
